import * as React from "react"
import { useEffect, useState } from 'react';
import { Link } from "gatsby"
import { Alert, Form, Button } from 'react-bootstrap';

import Layout from "../components/layout"
import Seo from "../components/seo"

import * as statusHelper from './../helper/status.js';

function writeLastListname(lastListName) {
  if (typeof window !== `undefined`) {
    window.localStorage.setItem('lastListName', lastListName);
  } 
}

const Share = () => { 
  let lastListName;
  if (typeof window !== `undefined`) {
    lastListName = window.localStorage.getItem('lastListName');
  }
    const [confirmed, setConfirmed] = useState(false);
    const [listName, setlistName] = useState(lastListName);
    const [loadResult, setLoadResult] = useState();
    const [loadVariant, setLoadVariant] = useState();
    const [saveResult, setSaveResult] = useState();
    const [saveVariant, setSaveVariant] = useState();
      
    useEffect(() => {
      writeLastListname(listName);
    }, [confirmed, listName]);

    const saveList = async () => {
      let result = await statusHelper.saveDb2(listName);
      if(result.status == 200) {
        setLoadResult(result.numberOfRecords + ' records saved.');
        setLoadVariant('success');

      } else {
        setLoadResult('No records saved - no selections done yet or error.');
        setLoadVariant('warning');
      }
    }

    const loadList = async () => {
      let response = await fetch(
        `https://rp22-service-w.azurewebsites.net/api/getList?code=PvwALChM12dA6w7Z_zUvtE-duBr1-70bbcWLdvj9PoUyAzFuaG3alg==&listname=${listName}`);
      const data = await response.json();      
      if(!data[0] || data[0]?.sessions?.length == 0) {
        setLoadResult('No records loaded - maybe the list name is wrong?');
        setLoadVariant('warning');
      } else {
        let newSet = [];
        data[0]?.sessions?.map((item, key) => {
          newSet.push({id: item.id, status: parseInt(item.status)});
        });
        if (typeof window !== `undefined`) {
          window.localStorage.setItem('selectedSessions', JSON.stringify(newSet));
        }        
        setLoadResult(data[0]?.sessions?.length + ' records loaded.');
        setLoadVariant('success');
      }
  
    }

    return(
  <Layout>
    <Seo title="Share" />
    <h1>Save and load <b>your list</b></h1>
    <p>Use this feature to open your list on any other devices you are using or send your friends a hint on your favorite items on the agenda.</p>
    <p>Choose your list's name wisely - everybody can load, everybody can save :)</p>
    <p>Want to try? 'Load From' and 'Save As' the list <code>demo</code> and play around. You can't do anything wrong.</p>
    <h2>Save As</h2>
    <p>Give your current selection in this browser a list name and write it to our database. Everything stays the same in this browser. If you make choices afterwards and want them to be included in your list, just 'Save As' the list again. Everything in our database using the very same name will be overwritten during 'Save As'.</p>
    <h2>Load From</h2>
    <p>Load any selection on a given list from our database. All your current selections in this browser will be replaced.</p>

    <Form>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label  >List Name</Form.Label>
    <Form.Control type="text"value={listName} onChange={(element) => setlistName(element.target.value.trim())} placeholder="snoopyOversnoop" />
    <Form.Text className="text-muted">
        Everybody who knows the list of your name can load and save it (that's the feature).
    </Form.Text>
  </Form.Group>

  <Form.Group className="mb-3" controlId="formBasicCheckbox">
  <Form.Check type="checkbox" checked={confirmed} onChange={(element) => setConfirmed(element.target.checked)} label="Please confirm warning: 'Save As' overwrites your list in the database. 'Load From' overwrites your current selection in this browser." />
  </Form.Group>
  <Button disabled={!(confirmed && listName?.length > 0)} variant="primary" onClick={saveList}>Save As</Button>&nbsp;
  <Button disabled={!(confirmed && listName?.length > 0)} variant="primary" onClick={loadList}>Load From</Button>
  <p></p>
  { (loadResult) 
  ? 
    <Alert variant={loadVariant} >
    {loadResult}
    </Alert>
  :
    <React.Fragment></React.Fragment>
  }
{ (saveResult) 
  ? 
    <Alert variant={saveVariant} >
    {saveResult}
    </Alert>
  :
    <React.Fragment></React.Fragment>
  }  
  
</Form>

  </Layout>
)}

export default Share